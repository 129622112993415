import baguetteBox from 'baguettebox.js';

function initBaguette() {
    baguetteBox.run('.gallery, .image-wrapper, .reference-grid-wrapper, a[rel="noopener"]', {
        noScrollbars: true,
        async: true,
        titleTag: true
    });
}

initBaguette();

document.addEventListener('paginator.fetched', () => {
    initBaguette()
});
